import logo from './logo.svg';
import './App.scss';
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/home';
import WebMaintenance from './pages/web_maintenance';
import Careers from './pages/careers';
import ContactUs from './pages/contactus';
import Tenders from './pages/tenders';
import Services from './pages/services';
import AboutUs from './pages/aboutus';
import Board from './pages/board';
import Management from './pages/management';
import Statement from './pages/statement';
import StaffLogin from './pages/stafflogin';
import CreateStaffUser from './pages/createstaffuser';
import ProtectedRoute from './components/Login/ProtectedRoute';
import Administration from './pages/staff/administration';
import { jwtDecode } from 'jwt-decode';
import GIS from './pages/staff/gis';
import PostNewCareer from './pages/staff/postnewcareer';
import PostNewTender from './pages/staff/postnewtender';
import ViewCareers from './pages/staff/viewcareers';
import ViewTenders from './pages/staff/viewtenders';
import ViewMedia from './pages/staff/viewmedia';
import PostNewMedia from './pages/staff/postnewmedia';

function App() {

  const [role, setRole] = useState();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      var decoded = jwtDecode(token);
      setRole(decoded.Role);
    } else {
      setRole();
    }
  }, []);

  return (
    <Router>
      <Routes>
        {/* <Route exact path = "/" element = {<HomePage />}/>
        <Route exact path = "/services" element = {<Services />}/>
        <Route exact path = "/tenders" element = {<Tenders />}/>
        <Route exact path = "/careers" element = {<Careers />}/>
        <Route exact path = "/contactus" element = {<ContactUs />}/>
        <Route exact path = "/stafflogin" element = {<StaffLogin />}/>
        <Route exact path = "/administrationcreate" element = {<CreateStaffUser />}/> */}

        {/* Admin Section */}
        {/* <Route exact path = "/admin" element = {<ProtectedRoute component={Administration} />}/>
        <Route exact path = "/admin/postcareer" element = {<ProtectedRoute component={PostNewCareer} />}/>
        <Route exact path = "/admin/posttender" element = {<ProtectedRoute component={PostNewTender} />}/>
        <Route exact path = "/admin/postmedia" element = {<ProtectedRoute component={PostNewMedia} />}/>
        <Route exact path = "/admin/mdia" element = {<ProtectedRoute component={ViewMedia} />}/>
        <Route exact path = "/admin/careers" element = {<ProtectedRoute component={ViewCareers} />}/>
        <Route exact path = "/admin/tenders" element = {<ProtectedRoute component={ViewTenders} />}/>
        <Route exact path = "/admin/gis" element = {<ProtectedRoute component={GIS} />}/> */}

        {/* <Route exact path = "/aboutus" element = {<AboutUs />}/>
        <Route exact path = "/aboutus/board" element = {<Board />}/>
        <Route exact path = "/aboutus/management" element = {<Management />}/> */}
        {/* <Route exact path = "/management" element = {<Services />}/> */}
        {/* <Route exact path = "/statements/chair" element = {<Statement />}/>
        <Route exact path = "/statements/md" element = {<Statement />}/>
        <Route exact path = "/newsandevents" element = {<Careers />}/>
        <Route exact path = "/reports" element = {<ContactUs />}/>
        <Route exact path = "/downloads" element = {<Careers />}/>
        <Route exact path = "/gallery" element = {<ContactUs />}/>
        <Route exact path = "/blog" element = {<ContactUs />}/> */}
        <Route exact path='/*' element = {<WebMaintenance />} />
      </Routes>
    </Router>
  );
}

export default App;
